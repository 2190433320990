import React, { useContext } from 'react';
import './Menu.css';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserLogContext } from '../../../Context';

const Menu = ({ isMenuActive, setIsMenuActive }) => {
  const { t } = useTranslation();
  const [, setIsLoggedIn] = useContext(UserLogContext);
  const history = useHistory();
  const onLogOut = () => {
    history.push('/');
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    localStorage.removeItem('guid');
    localStorage.removeItem('authenticated');
    localStorage.removeItem('userInfo');
    setIsLoggedIn(false);
  };
  return (
    <section className={isMenuActive ? 'user-menu active' : 'user-menu'}>
      <div className="user-menu__close" onClick={() => setIsMenuActive(false)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
        </svg>
      </div>
      <ul>
        <li>
          <NavLink
            onClick={() => setIsMenuActive(false)}
            to={'/user/profile'}
            activeClassName="user-active"
          >
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="#BCBCBC"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.41635 18.1374C2.36364 17.1634 1.524 15.982 0.950319 14.6675C0.376637 13.3531 0.0813373 11.9341 0.0830149 10.4999C0.0830149 4.74679 4.74656 0.083252 10.4997 0.083252C16.2528 0.083252 20.9163 4.74679 20.9163 10.4999C20.918 11.9341 20.6227 13.3531 20.049 14.6675C19.4754 15.982 18.6357 17.1634 17.583 18.1374C17.3358 17.1335 16.8784 16.1935 16.2409 15.3795C15.6034 14.5656 14.8005 13.8962 13.8851 13.4155C14.6939 12.7234 15.2712 11.8 15.5392 10.7698C15.8072 9.73952 15.7531 8.65189 15.3842 7.65333C15.0152 6.65478 14.3491 5.79326 13.4756 5.18478C12.6021 4.57631 11.5632 4.25011 10.4986 4.25011C9.4341 4.25011 8.39513 4.57631 7.52163 5.18478C6.64814 5.79326 5.98207 6.65478 5.61312 7.65333C5.24417 8.65189 5.19006 9.73952 5.45809 10.7698C5.72611 11.8 6.30339 12.7234 7.11218 13.4155C6.19719 13.8964 5.39463 14.5659 4.75754 15.3799C4.12045 16.1938 3.66332 17.1337 3.41635 18.1374ZM5.30176 19.5291C6.88215 20.4409 8.67514 20.9195 10.4997 20.9166C12.3924 20.9166 14.1663 20.4114 15.6976 19.5291C15.6139 18.2074 15.0298 16.9672 14.0642 16.0609C13.0986 15.1545 11.824 14.65 10.4997 14.65C9.17534 14.65 7.90073 15.1545 6.93514 16.0609C5.96954 16.9672 5.38548 18.2074 5.30176 19.5291ZM10.4997 6.33325C10.0893 6.33325 9.68294 6.41408 9.3038 6.57113C8.92465 6.72817 8.58016 6.95836 8.28997 7.24854C7.99979 7.53873 7.7696 7.88322 7.61256 8.26237C7.45551 8.64151 7.37468 9.04787 7.37468 9.45825C7.37468 9.86863 7.45551 10.275 7.61256 10.6541C7.7696 11.0333 7.99979 11.3778 8.28997 11.668C8.58016 11.9581 8.92465 12.1883 9.3038 12.3454C9.68294 12.5024 10.0893 12.5833 10.4997 12.5833C11.3285 12.5833 12.1233 12.254 12.7094 11.668C13.2954 11.0819 13.6247 10.2871 13.6247 9.45825C13.6247 8.62945 13.2954 7.83459 12.7094 7.24854C12.1233 6.66249 11.3285 6.33325 10.4997 6.33325Z"
              />
            </svg>
            <span>{t('My profile')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={() => setIsMenuActive(false)}
            to={'/user/posts'}
            activeClassName="user-active"
          >
            <svg
              width="19"
              height="21"
              viewBox="0 0 19 21"
              fill="#BCBCBC"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.125 17.7916C0.125 18.6204 0.45424 19.4152 1.04029 20.0013C1.62634 20.5873 2.4212 20.9166 3.25 20.9166H15.75C16.5788 20.9166 17.3737 20.5873 17.9597 20.0013C18.5458 19.4152 18.875 18.6204 18.875 17.7916V7.19575C18.8745 6.36738 18.5452 5.57308 17.9594 4.98742L13.9719 0.997835C13.3858 0.412125 12.5911 0.0831527 11.7625 0.083252H3.25C2.4212 0.083252 1.62634 0.412492 1.04029 0.998543C0.45424 1.58459 0.125 2.37945 0.125 3.20825V17.7916ZM5.33333 10.4999C5.05707 10.4999 4.79211 10.6097 4.59676 10.805C4.40141 11.0004 4.29167 11.2653 4.29167 11.5416C4.29167 11.8179 4.40141 12.0828 4.59676 12.2782C4.79211 12.4735 5.05707 12.5833 5.33333 12.5833H13.6667C13.9429 12.5833 14.2079 12.4735 14.4032 12.2782C14.5986 12.0828 14.7083 11.8179 14.7083 11.5416C14.7083 11.2653 14.5986 11.0004 14.4032 10.805C14.2079 10.6097 13.9429 10.4999 13.6667 10.4999H5.33333ZM5.33333 14.6666C5.05707 14.6666 4.79211 14.7763 4.59676 14.9717C4.40141 15.167 4.29167 15.432 4.29167 15.7083C4.29167 15.9845 4.40141 16.2495 4.59676 16.4448C4.79211 16.6402 5.05707 16.7499 5.33333 16.7499H9.5C9.77627 16.7499 10.0412 16.6402 10.2366 16.4448C10.4319 16.2495 10.5417 15.9845 10.5417 15.7083C10.5417 15.432 10.4319 15.167 10.2366 14.9717C10.0412 14.7763 9.77627 14.6666 9.5 14.6666H5.33333Z"
              />
            </svg>
            <span>{t('My announcements')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={() => setIsMenuActive(false)}
            to={'/user/wishlist'}
            activeClassName="user-active"
          >
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="#BCBCBC"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M15.1872 0.125C18.3518 0.125 20.9163 2.72917 20.9163 6.375C20.9163 13.6667 13.1038 17.8333 10.4997 19.3958C7.89551 17.8333 0.0830078 13.6667 0.0830078 6.375C0.0830078 2.72917 2.68717 0.125 5.81217 0.125C7.74968 0.125 9.45801 1.16667 10.4997 2.20833C11.5413 1.16667 13.2497 0.125 15.1872 0.125Z" />
            </svg>
            <span>{t('My choices')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={() => setIsMenuActive(false)}
            to={'/user/balance'}
            activeClassName="user-active"
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="#BCBCBC"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5.1411 4.4607L13.9115 2.06676L13.447 1.12796C13.1431 0.517744 12.402 0.265751 11.7917 0.569625L3.94043 4.4607H5.1411Z" />
              <path d="M16.634 2.13599C16.5253 2.13599 16.4166 2.15081 16.3079 2.18046L14.2475 2.74373L7.95508 4.46074H15.0974H18.2102L17.8248 3.04761C17.6766 2.49668 17.1775 2.13599 16.634 2.13599Z" />
              <path d="M19.4974 5.32544H19.2157H18.8328H18.4499H15.5272H4.78787H3.37967H2.19382H1.97395H1.23773C0.847389 5.32544 0.499045 5.50579 0.271757 5.7899C0.167996 5.92084 0.0889388 6.07154 0.0444694 6.23706C0.0172937 6.34082 0 6.44953 0 6.5607V6.70893V8.11713V20.324C0 21.0059 0.553397 21.5592 1.23526 21.5592H19.4949C20.1768 21.5592 20.7302 21.0059 20.7302 20.324V16.8776H13.4001C12.2414 16.8776 11.3002 15.9363 11.3002 14.7777V13.6462V13.2632V12.8803V12.0304C11.3002 11.4622 11.5274 10.9459 11.8956 10.5679C12.2217 10.2319 12.659 10.0046 13.1481 9.94779C13.2296 9.93793 13.3136 9.93296 13.3976 9.93296H19.7024H20.0853H20.4683H20.7302V6.5607C20.7326 5.87884 20.1792 5.32544 19.4974 5.32544Z" />
              <path d="M21.5965 11.1139C21.473 11.0003 21.3272 10.9138 21.1642 10.857C21.0382 10.815 20.9048 10.7903 20.764 10.7903H20.7319H20.7071H20.3242H18.9432H13.3993C12.7174 10.7903 12.1641 11.3437 12.1641 12.0255V12.6407V13.0236V13.4065V14.7752C12.1641 15.4571 12.7174 16.0105 13.3993 16.0105H20.7319H20.764C20.9048 16.0105 21.0382 15.9858 21.1642 15.9438C21.3272 15.8894 21.473 15.8005 21.5965 15.6868C21.8436 15.462 21.9992 15.1359 21.9992 14.7752V12.0255C21.9992 11.6648 21.8436 11.3387 21.5965 11.1139ZM15.9786 13.6462C15.9786 13.9871 15.7019 14.2638 15.3609 14.2638H14.9508C14.6099 14.2638 14.3332 13.9871 14.3332 13.6462V13.2361C14.3332 13.0384 14.4246 12.863 14.5704 12.7518C14.6766 12.6703 14.8076 12.6184 14.9508 12.6184H15.0546H15.3609C15.7019 12.6184 15.9786 12.8951 15.9786 13.2361V13.6462Z" />
            </svg>
            <span>{t('My account')}</span>
          </NavLink>
        </li>
        {/* <li>
          <NavLink
            onClick={() => setIsMenuActive(false)}
            to={'/user/messages'}
            activeClassName="user-active"
          >
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="#BCBCBC"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_145:871)">
                <path d="M20.9784 2.74023H2.02132C1.71015 2.74023 1.41883 2.81674 1.15527 2.94283L11.4549 13.2425L13.7634 11.0239C13.7634 11.0239 13.7636 11.0237 13.7636 11.0236C13.7637 11.0235 13.7639 11.0234 13.7639 11.0234L21.8447 2.94301C21.5811 2.81683 21.2896 2.74023 20.9784 2.74023Z" />
                <path d="M22.7979 3.896L15.1934 11.5001L22.7976 19.1043C22.9237 18.8408 23.0002 18.5495 23.0002 18.2383V4.76173C23.0002 4.45074 22.9239 4.15946 22.7979 3.896Z" />
                <path d="M0.202598 3.89575C0.076502 4.15931 0 4.45063 0 4.7618V18.2384C0 18.5494 0.0764121 18.8407 0.202418 19.1042L7.80693 11.5001L0.202598 3.89575Z" />
                <path d="M14.2405 12.453L11.9317 14.6718C11.8001 14.8034 11.6276 14.8692 11.4553 14.8692C11.2829 14.8692 11.1105 14.8034 10.9789 14.6718L8.75992 12.4529L1.15527 20.057C1.41888 20.1832 1.71037 20.2598 2.02164 20.2598H20.9787C21.2898 20.2598 21.5812 20.1833 21.8447 20.0572L14.2405 12.453Z" />
              </g>
              <defs>
                <clipPath id="clip0_145:871">
                  <rect width="23" height="23" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span>{t('Messages')}</span>
          </NavLink>
        </li> */}
        <li>
          <NavLink activeClassName="user-active" to="/user/account-delete">
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="#BCBCBC"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m20.015 6.506h-16v14.423c0 .591.448 1.071 1 1.071h14c.552 0 1-.48 1-1.071 0-3.905 0-14.423 0-14.423zm-5.75 2.494c.414 0 .75.336.75.75v8.5c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-8.5c0-.414.336-.75.75-.75zm-4.5 0c.414 0 .75.336.75.75v8.5c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-8.5c0-.414.336-.75.75-.75zm-.75-5v-1c0-.535.474-1 1-1h4c.526 0 1 .465 1 1v1h5.254c.412 0 .746.335.746.747s-.334.747-.746.747h-16.507c-.413 0-.747-.335-.747-.747s.334-.747.747-.747zm4.5 0v-.5h-3v.5z"
                fillRule="nonzero"
              />
            </svg>
            <span>{t('delete account')}</span>
          </NavLink>
        </li>
        <li>
          <Link
            onClick={() => {
              setIsMenuActive(false);
              onLogOut();
            }}
            to="/"
          >
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="#BCBCBC"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0.0734539 10.1755C0.122329 10.0586 0.192272 9.95224 0.281397 9.86311L3.15545 6.98905C3.53015 6.6153 4.13583 6.6153 4.51052 6.98905C4.88521 7.36375 4.88521 7.97037 4.51052 8.34412L3.2714 9.58325H8.62469C9.15463 9.58325 9.58301 10.0126 9.58301 10.5416C9.58301 11.0706 9.15463 11.4999 8.62469 11.4999H3.27144L4.51057 12.739C4.88526 13.1137 4.88526 13.7203 4.51057 14.0941C4.32369 14.2819 4.07837 14.3749 3.83301 14.3749C3.58765 14.3749 3.34233 14.2819 3.15545 14.0941L0.281397 11.22C0.192272 11.1318 0.122329 11.0255 0.0734539 10.9076C-0.0233078 10.6739 -0.0233078 10.4094 0.0734539 10.1755Z" />
              <path d="M6.70641 13.4167C7.23636 13.4167 7.66473 13.846 7.66473 14.375V19.1667H11.498V3.83332C11.498 3.41069 11.776 3.03694 12.1814 2.91525L15.5097 1.91668H7.66473V6.70836C7.66473 7.23736 7.23636 7.66668 6.70641 7.66668C6.17647 7.66668 5.74809 7.23736 5.74809 6.70836V0.958363C5.74809 0.429318 6.17647 0 6.70641 0H22.0397C22.0742 0 22.1049 0.014375 22.1384 0.0181934C22.1835 0.023 22.2247 0.0306367 22.2678 0.0411934C22.3684 0.0670684 22.4595 0.106375 22.5447 0.160012C22.5658 0.173443 22.5917 0.174387 22.6118 0.189705C22.6195 0.1955 22.6224 0.206057 22.6301 0.211807C22.7345 0.294193 22.8217 0.394818 22.885 0.514625C22.8984 0.5405 22.9013 0.568307 22.9118 0.595125C22.9425 0.667943 22.976 0.738875 22.9875 0.819375C22.9923 0.848125 22.9837 0.874943 22.9846 0.90275C22.9856 0.921932 22.998 0.939182 22.998 0.958318V20.125C22.998 20.5821 22.6751 20.9751 22.2275 21.0642L12.6442 22.9809C12.5819 22.9943 12.5187 23 12.4564 23C12.237 23 12.0213 22.9244 11.8488 22.7825C11.6275 22.6004 11.4981 22.3292 11.4981 22.0417V21.0834H6.70641C6.17647 21.0834 5.74809 20.654 5.74809 20.125V14.375C5.74809 13.846 6.17647 13.4167 6.70641 13.4167Z" />
            </svg>
            <span>{t('Exit Profile')}</span>
          </Link>
        </li>
      </ul>
    </section>
  );
};

export default Menu;
