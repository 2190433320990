import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PageLayout from '../PageLayout';
import UserPageLayout from '../UserPages/UserPageLayout';

const PrivateRoute = ({
  component: Component,
  footer = true,
  userPage = true,
  ...rest
}) => {
  const isAuthed = localStorage.getItem('authenticated');
  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          isAuthed ? (
            <PageLayout footer={footer}>
              {userPage ? (
                <UserPageLayout>
                  <Component {...props} />
                </UserPageLayout>
              ) : (
                <Component {...props} />
              )}
            </PageLayout>
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    </>
  );
};

export default PrivateRoute;
