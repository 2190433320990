import React from 'react';
import '../app-auth.css';
import logoAuth from '../../../Assets/Images/auth.png';
import { Link } from 'react-router-dom';
import logo from '../../../Assets/Images/logo-footer.png';
import round from '../../../Assets/Images/round.png';

const AuthLayout = ({ children }) => {
  return (
    <div className="app-auth">
      <div
        className="app-auth__left"
        style={{ backgroundImage: `url(${logoAuth})` }}
      >
        <Link to="/" className="app-auth__image">
          <img src={logo} alt="Royxatdan otish" />
        </Link>
      </div>
      <div className="app-auth__right">
        <div className="app-auth__dots top">
          <img src={round} alt="Dacha turizm" />
          <img src={round} alt="Dacha turizm" />
          <img src={round} alt="Dacha turizm" />
          <img src={round} alt="Dacha turizm" />
          <img src={round} alt="Dacha turizm" />
          <img src={round} alt="Dacha turizm" />
          <img src={round} alt="Dacha turizm" />
          <img src={round} alt="Dacha turizm" />
        </div>
        {children}
        <div className="app-auth__dots bottom">
          <img src={round} alt="Dacha turizm" />
          <img src={round} alt="Dacha turizm" />
          <img src={round} alt="Dacha turizm" />
          <img src={round} alt="Dacha turizm" />
          <img src={round} alt="Dacha turizm" />
          <img src={round} alt="Dacha turizm" />
          <img src={round} alt="Dacha turizm" />
          <img src={round} alt="Dacha turizm" />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
