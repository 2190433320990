import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteUser } from '../../../BaseApi/BaseApi';
import { UserLogContext } from '../../../Context';
import Modal from '../../Modal/Modal';
import './AccountDelete.css';

const AccountDelete = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [, setIsLoggedIn] = useContext(UserLogContext);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleDelete = () => {
    deleteUser()
      .then(() => {
        history.push('/');
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        localStorage.removeItem('guid');
        localStorage.removeItem('authenticated');
        localStorage.removeItem('userInfo');
        setIsLoggedIn(false);

        toast.success(t('Successfully deleted'));
      })
      .catch((err) => {
        toast.error(t('Something went wrong try again'));
        console.log(err);
      });
  };

  return (
    <div className="wrapper">
      <h3>{t('delete account')}</h3>
      <button className="delete-btn" onClick={() => setIsModalOpen(true)}>
        {t('Delete')}
      </button>
      {isModalOpen && (
        <Modal
          closeModal={() => setIsModalOpen(false)}
          onButtonClick={handleDelete}
        />
      )}
    </div>
  );
};

export default AccountDelete;
