import React, {useState} from 'react';
import Menu from "./Menu";
import './UserPages.css';

const UserPageLayout = ({children}) => {
    const [isMenuActive, setIsMenuActive] = useState(false);
    return (
        <div className='user-overlay'>
            <div className="custom_container">
                <div className="user-menu__close" onClick={() => setIsMenuActive(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"/>
                    </svg>
                </div>
                <div className="user-overlay__wrapper">
                    <Menu isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive}/>
                    <div className='user-info'>
                        {children}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default UserPageLayout;