import axios from 'axios';

const apiBASE = 'https://dachaturzm.uz/api/';
const newApiBASE = 'https://api.dachaturizm.uz/api/v1/';

export const createUser = async (data) => {
  return await axios.post(newApiBASE + 'account/register/', data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const getData = async (url) => {
  const res = await axios(apiBASE + url);

  if (!res.statusText) {
    throw new Error(`Could not fetch url: ${res.request}, status:${res.statusText}`);
  }
  return res;
};

const token = localStorage.getItem('access');

const putNewData = async (url, data, contentType = 'application/json') => {
  const token = localStorage.getItem('access');
  return await axios.put(newApiBASE + url, data, {
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${token}`,
    },
  });
};

const deleteNewData = async (url) => {
  const token = localStorage.getItem('access');
  return await axios.delete(newApiBASE + url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteMessage = async (id) => postUser('messages/delete-chat/', id);
// GET WITH TOKEN
export const getWithToken = async (url) => {
  const token = localStorage.getItem('access');
  const res = await axios.get(apiBASE + url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!res.statusText) {
    throw new Error(`Could not fetch url: ${res.request}, status:${res.statusText}`);
  }
  return res;
};

// GET USER POSTS
export const getUserPosts = async (page) =>
  getNewWithToken(`estate/userestate-list/?page=${page}`);

// GET USER WISHLISTS
const postUser = async (url, id) => {
  const token = localStorage.getItem('access');
  const res = await axios.post(apiBASE + url, JSON.stringify({ estate: id }), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!res.statusText) {
    throw new Error(`Could not fetch url: ${res.request}, status:${res.statusText}`);
  }
  return res;
};
//ADD TO WISHLIST
export const postUserWishlists = async (guid) =>
  postNewData('estate/favourite/create/', { estate: guid });

// WISHLIST REMOVE
export const removeFromWishList = async (guid) =>
  deleteNewData(`estate/favourite/${guid}/delete/`);

// REFRESH TOKEN
export const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refresh');
  const res = await axios.post(
    apiBASE + 'token/refresh/',
    JSON.stringify({
      refresh: refreshToken,
    }),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  return res;
};

//GET SIMPLE DATA
export const getSimpleData = async (guid, page) =>
  getNewData(`estate/list/?category=${guid}&page=${page}`);

//GET CURRENCIES
export const getCurr = async () => getNewData('estate/currency/list/');

// POST DATA
export const postDataBy = async (data) => postNewData('estate/create/', data);

// PATCH DATA
export const patchDataBy = async (data, guid) =>
  putNewData(`estate/${guid}/user-estate-update/`, data);

// DELETE DATA
export const deleteDataBy = async (guid) =>
  deleteNewData(`estate/${guid}/userestate-delete/`);

// CHANGE USER DATA
export const changeUserData = async (url, data) => putNewData(url, data);

// GET STATIC PAGES
export const getStaticPages = async () => getData(`staticpages/`);

// GET USER CHATS
export const getUserChats = async () => getWithToken(`messages/mychats/`);
//SMS SEND
export const smsSend = (body) =>
  fetch(newApiBASE + 'account/send-otp/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: body,
  });
//SMS RESEND
export const smsResend = (body) =>
  fetch(newApiBASE + 'account/resend-otp/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: body,
  });

// SMS VERIFY
export const smsVerify = (body) =>
  fetch(newApiBASE + 'account/verify/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: body,
  });

//RESET PASSWORD - send message
export const resetPasswordPhone = async (data) => {
  return await axios.post(newApiBASE + 'account/password-reset/', data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
//RESET PASSWORD - verify message
export const resetPasswordVerify = async (data) => {
  return await axios.post(newApiBASE + 'account/password-reset-check/', data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
//RESET PASSWORD - reset password
export const resetPassword = async (data) => {
  return await axios.post(newApiBASE + 'account/password-reset-confirm/', data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

// SEARCH PAGE
export const searchData = async (url, page) =>
  getNewData('estate/estate-list-search/' + url + '&page=' + page);

// PUT RATING
export const putRating = (data) => postNewData(`estate/rate/create/`, data);
// GET RATING
export const getRatings = async () => getNewWithToken('ratings/mine/');
// GET SERVICES PAGE
export const getServices = async () => getNewData('estate/service/list/');

// FACILITIES NY TYPE
export const getFacilityByType = async (slug) =>
  getData(`facilities/?category=${slug}`);

export const getExpenses = async (url) => getNewWithToken(`transaction/list/`);

const getNewData = async (url) => {
  const res = await axios(newApiBASE + url);

  if (!res.statusText) {
    console.log(`Could not fetch url: ${res.request}, status:${res.statusText}`);
  }
  return res;
};

// LOGIN USER
export const loginUser = async (data) => {
  return await axios.post(newApiBASE + 'account/login/', data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

//GET RELATED DATA
export const getRelatedData = async (guid) =>
  getNewData(`estate/${guid}/related-estate/`).then((data) => data);

//GET MENU
export const getMenu = async () => getNewData('category/list/');

// GET ADDRESSES
export const getAddresses = async () => getNewData('estate/region/region-list/');

// GET ADDRESSES
export const getDistricts = async (guid) =>
  getNewData('estate/region/district/' + guid + '/');

// Get top banners
export const getTopBanners = async () => getNewData(`estate/homepage-list/`);

//GET TOP DATA
export const getTopData = async (categoryGuid, tariffGuid) =>
  getNewData(
    `estate/estate-list-search/?category=${categoryGuid}&tariff_top=${tariffGuid}`
  );

export const getHomeTopData = async () => getNewData(`estate/homepage-list/`);

//GET DATA BY ID
export const getDataById = async (guid) => getNewData(`estate/${guid}/detail/`);

// GET ADS
export const getAds = async () => getNewData('estate/ads_plus/');

// GET TARRIFS
export const getTariffs = async () => getNewData('tariff/list/');

export const updateTariff = async (estateGuid, tariffGuid) => {
  return putNewData(`estate/${estateGuid}/estate-tariff-update/`, {
    user: localStorage.getItem('guid'),
    tariff: tariffGuid,
  });
};
//GET BANNER
export const getBanners = async () => getNewData(`banner/list/`);

// GET USER
export const getUserById = async (guid) =>
  await getNewData('account/' + guid + '/detail/');

// GET USER WISHLISTS
export const getUserWishlists = async () => {
  const token = localStorage.getItem('access');
  const res = await axios(newApiBASE + 'estate/favourite/list/', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!res.statusText) {
    throw new Error(`Could not fetch url: ${res.request}, status:${res.statusText}`);
  }
  return res;
};

//GET RATING
export const getRatingRelated = async (guid) =>
  getNewData(`estate/${guid}/related-estate/`);

const postNewData = async (url, data, contentType = 'application/json') => {
  const token = localStorage.getItem('access');
  return await axios.post(newApiBASE + url, data, {
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${token}`,
    },
  });
};

// GET WITH TOKEN
export const getNewWithToken = async (url) => {
  const token = localStorage.getItem('access');
  const res = await axios.get(newApiBASE + url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!res.statusText) {
    throw new Error(`Could not fetch url: ${res.request}, status:${res.statusText}`);
  }
  return res;
};

export const getUserExpenses = async () => {
  return getNewWithToken('account/balance/list/');
};

export const getUserTransactions = async () => {
  return getNewWithToken('transaction/list/');
};

// GET FACILITIES
export const getFacilities = async () =>
  getNewData('estate/facility/facility-list/');

export const createMainImage = async (image) => {
  const data = new FormData();
  data.append('images', image);
  return postNewData('upload/mainimage-create/', data, 'multipart/form-data');
};

export const createPopularPlace = async (title) => {
  return postNewData('estate/popularplace-create/', { title });
};

export const createImage = async (image) => {
  const data = new FormData();
  data.append('images', image);
  return postNewData('upload/images-create/', data, 'multipart/form-data');
};

export const createImages = async (images) => {
  console.log(images);
  let promises = images.map((image) => {
    return createImage(image);
  });

  return Promise.all(promises);
};

export const createProfileImage = async (picture) => {
  const data = new FormData();
  data.append('profile_picture', picture);
  return postNewData('upload/profileimage-create/', data, 'multipart/form-data');
};

export const getServiceItem = async (guid) => {
  return getNewData(`estate/service/serviceitem-list/${guid}/`);
};

export const getPopularPlace = async () => {
  return getNewData('estate/popularplace-list/');
};

export const makePayment = async (type, amount) => {
  return postNewData(`transaction/initialize_payment/`, {
    transaction_type: type,
    price: amount,
  });
};

export const deleteUser = async () => {
  const guid = localStorage.getItem('guid');
  return putNewData('account/delete/' + guid + '/');
};

export const getContacts = async () => {
  return getNewData('common/contact-list/');
};

export const getSocialNetwork = async () => {
  return getNewData('common/socialnetwork-list/');
};

export const getAdvertisingPlans = async () => {
  return getNewData('common/appadvertising-list/');
};

export const getMinMaxPrice = async () => {
  return getNewData('estate/all-estate-price/');
};
