import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Spinner from '../AppSpinner';
import loadable from '@loadable/component';
import PageLayout from '../PageLayout';
import AuthLayout from '../AppAuth/AuthLayout/AuthLayout';
import PrivateRoute from './PrivateRoute';
import AccountDelete from '../UserPages/AccountDelete/AccountDelete';
import UserPageLayout from '../UserPages/UserPageLayout';

const NotFoundPage = loadable(() => import('../Pages/NotFoundPage'));

const PolicyPage = loadable(() => import('../Pages/PolicyPage'));

const Auth = loadable(() => import('../AppAuth/Auth/Auth'));

const Login = loadable(() => import('../AppAuth/Login/Login'));

const ResetPassword = loadable(() =>
  import('../AppAuth/ResetPassword/ResetPassword')
);

const UserDashboard = loadable(() => import('../UserPages/DashBoard'));
const UserPosts = loadable(() => import('../UserPages/Posts'));
const UserWishlist = loadable(() => import('../UserPages/Wishlist'));
const UserBalance = loadable(() => import('../UserPages/Balance'));
const UserMessage = loadable(() => import('../UserPages/Message'));
const AnnouncementPage = loadable(() => import('../Pages/AnnouncementPage'));

const AdvertisingPage = loadable(() => import('../Pages/AdvertisingPage'));

const ServicePage = loadable(() => import('../Pages/ServicesPage'));

const DetailPage = loadable(() => import('../Pages/DetailPage'));

const HomePage = loadable(() => import('../Pages/HomePage'));

const ListPage = loadable(() => import('../Pages/ListPage'));
const SearchPage = loadable(() => import('../Pages/ListPage/SearchPage'));
const PostPage = loadable(() => import('../Pages/CrudPage/Post'));
const EditPage = loadable(() => import('../Pages/CrudPage/Edit'));
const AccountDeletePage = loadable(() =>
  import('../../Components/UserPages/AccountDelete/AccountDelete')
);

export const MainSpinner = () => (
  <div className="main-spinner">
    <div className="main-spinner_wrapper">
      <Spinner />
    </div>
  </div>
);

const Routes = () => {
  return (
    <Switch>
      <Route
        path="/"
        exact
        render={() => (
          <PageLayout>
            <Suspense fallback={<MainSpinner />}>
              <HomePage />
            </Suspense>
          </PageLayout>
        )}
      />

      <Route
        path="/search/:searchQuery"
        exact={true}
        render={() => (
          <PageLayout>
            <Suspense fallback={<MainSpinner />}>
              <SearchPage />
            </Suspense>
          </PageLayout>
        )}
      />

      <Route
        path="/estate/:guid"
        exact
        render={() => (
          <PageLayout>
            <Suspense fallback={<MainSpinner />}>
              <ListPage />
            </Suspense>
          </PageLayout>
        )}
      />

      <Route
        path="/estate/detail/:guid"
        exact={true}
        render={() => (
          <PageLayout>
            <Suspense fallback={<MainSpinner />}>
              <DetailPage />
            </Suspense>
          </PageLayout>
        )}
      />
      <Route
        path="/saytdareklama"
        exact
        render={() => (
          <PageLayout>
            <Suspense fallback={<MainSpinner />}>
              <AnnouncementPage />
            </Suspense>
          </PageLayout>
        )}
      />

      <Route
        path="/xizmatlar"
        exact
        render={() => (
          <PageLayout>
            <Suspense fallback={<MainSpinner />}>
              <ServicePage />
            </Suspense>
          </PageLayout>
        )}
      />

      <Route
        path="/login"
        exact
        render={() => (
          <AuthLayout>
            <Suspense fallback={<MainSpinner />}>
              <Login />
            </Suspense>
          </AuthLayout>
        )}
      />
      <Route
        path="/auth"
        exact
        render={() => (
          <AuthLayout>
            <Suspense fallback={<MainSpinner />}>
              <Auth />
            </Suspense>
          </AuthLayout>
        )}
      />
      <Route
        path="/reset-password"
        exact
        render={() => (
          <AuthLayout>
            <Suspense fallback={<MainSpinner />}>
              <ResetPassword />
            </Suspense>
          </AuthLayout>
        )}
      />

      <PrivateRoute
        path="/user/edit/:guid"
        component={EditPage}
        userPage={false}
        exact={true}
      />

      <PrivateRoute
        path="/user/account-delete"
        component={AccountDeletePage}
        userPage={true}
        exact={true}
      />

      <PrivateRoute path="/user/post" component={PostPage} userPage={false} />

      <PrivateRoute path="/user/profile" component={UserDashboard} footer={false} />

      <PrivateRoute path="/user/posts" footer={false} component={UserPosts} />

      <PrivateRoute path="/user/wishlist" footer={false} component={UserWishlist} />

      <PrivateRoute path="/user/balance" footer={false} component={UserBalance} />

      <PrivateRoute path="/user/messages" footer={false} component={UserMessage} />

      <Route
        path="/staticpages/:slug"
        exact
        render={() => (
          <PageLayout>
            <Suspense fallback={<MainSpinner />}>
              <PolicyPage />
            </Suspense>
          </PageLayout>
        )}
      />

      <Route
        path="*"
        exact
        render={() => (
          <PageLayout>
            <Suspense fallback={<MainSpinner />}>
              <NotFoundPage />
            </Suspense>
          </PageLayout>
        )}
      />
    </Switch>
  );
};

export default Routes;
