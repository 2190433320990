import React, { useContext, useState } from 'react';
import './AppMenu.css';
import { Link, NavLink } from 'react-router-dom';
import { LangContext, MenuContext, UserInfoContext } from '../../Context';
import logoTop from '../../Assets/Images/logo-top.png';
import { useTranslation } from 'react-i18next';

export const languages = [
  { id: 1, language: 'uz' },
  { id: 2, language: 'ru' },
  { id: 3, language: 'en' },
];

const AppMenu = () => {
  const [dropdown, setDropdown] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();
  const [lang, setLang] = useContext(LangContext);
  const { menu } = useContext(MenuContext);
  const { userInfo } = useContext(UserInfoContext);
  const isLoggedIn = localStorage.getItem('authenticated');

  const menuClose = (e) => {
    if (e.target) {
      setIsMenuOpen(false);
    }
  };

  return (
    <header className="menu">
      <div className="menu__fixed">
        <div className="container">
          <div className="menu__wrapper">
            <div className="menu__logo">
              <Link to="/">
                <img
                  src={logoTop}
                  alt={'Dacha turizm logo'}
                  width="165"
                  height="56"
                />
              </Link>
            </div>
            <nav className={isMenuOpen ? 'menu__nav active' : 'menu__nav'}>
              <div className="menu__nav-wrapper">
                <div onClick={() => setIsMenuOpen(false)} className="menu__close">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414                       4.95-4.95-4.95-4.95L7.05 5.636z" />
                  </svg>
                  <ul>
                    {languages.map((item) => (
                      <li
                        className={item.language === lang ? 'active' : ''}
                        key={item.id}
                        onClick={() => setLang(item.language)}
                      >
                        {item.language}
                      </li>
                    ))}
                  </ul>
                </div>
                <ul className="menu__list">
                  {menu.results &&
                    menu.results.map((item) => {
                      let title = item.title;
                      if (lang === 'en') {
                        title = item.title_en;
                      } else if (lang === 'ru') {
                        title = item.title_ru;
                      } else if (lang === 'uz') {
                        title = item.title_uz;
                      }
                      return (
                        <li key={item.id}>
                          <NavLink
                            onClick={() => setIsMenuOpen(false)}
                            activeClassName="active_link"
                            to={`/estate/${item.guid}`}
                          >
                            <img src={item.category_icon} alt={''} />
                            {title}
                          </NavLink>
                        </li>
                      );
                    })}
                  <li>
                    <NavLink
                      activeClassName="active_link"
                      to="/xizmatlar"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <span>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="black"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M20.22 6.86012C18.22 6.26012 16.16 6.82012 14.83 8.15012L12 10.6601L10.48 12.0001H10.49L7.8 14.3901C6.99 15.2001 5.85 15.5401 4.68 15.3101C3.43 15.0601 2.4 14.0601 2.11 12.8201C1.59 10.5901 3.27 8.62012 5.4 8.62012C6.31 8.62012 7.16 8.97012 7.84 9.65012L8.31 10.0601C8.69 10.4001 9.26 10.4001 9.64 10.0601C10.09 9.66012 10.09 8.96012 9.64 8.56012L9.22 8.20012C8.2 7.18012 6.84 6.62012 5.4 6.62012C2.42 6.62012 0 9.04012 0 12.0001C0 14.9601 2.42 17.3801 5.4 17.3801C6.84 17.3801 8.2 16.8201 9.17 15.8501L12 13.3501L12.01 13.3601L13.52 12.0001H13.51L16.2 9.61012C17.01 8.80012 18.15 8.46012 19.32 8.69012C20.57 8.94012 21.6 9.94012 21.89 11.1801C22.41 13.4101 20.73 15.3801 18.6 15.3801C17.7 15.3801 16.84 15.0301 16.16 14.3501L15.68 13.9301C15.3 13.5901 14.73 13.5901 14.35 13.9301C13.9 14.3301 13.9 15.0301 14.35 15.4301L14.77 15.8001C15.79 16.8101 17.14 17.3701 18.59 17.3701C21.86 17.3701 24.45 14.4701 23.92 11.1201C23.62 9.13012 22.15 7.43012 20.22 6.86012Z" />
                        </svg>
                      </span>
                      {t('Services')}
                    </NavLink>
                  </li>
                </ul>
                <div
                  className="menu__lang"
                  onMouseEnter={() => setDropdown(true)}
                  onMouseLeave={() => setDropdown(false)}
                >
                  <span onClick={() => setDropdown((d) => !d)}>
                    {lang}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                    >
                      <path
                        d="M4.99996 6L0.756958 1.757L2.17196 0.343002L4.99996 3.172L7.82796 0.343002L9.24296 1.757L4.99996 6Z"
                        fill="#3B2F43"
                      />
                    </svg>
                  </span>
                  <ul className={`menu__dropdown ${dropdown ? 'active' : ''}`}>
                    {languages.map((item) => (
                      <li
                        key={item.id}
                        onClick={() => {
                          setDropdown(false);
                          setLang(item.language);
                        }}
                      >
                        {item.language}
                      </li>
                    ))}
                  </ul>
                </div>
                {isLoggedIn && (
                  <div className="menu__user">
                    <NavLink
                      onClick={() => setIsMenuOpen(false)}
                      to="/user/profile"
                      data-label={t('My profile')}
                    >
                      {userInfo && userInfo.profile_pic !== null ? (
                        <img
                          src={`https://api.dachaturizm.uz${userInfo.profile_pic}`}
                          alt="Dacha turizm profil"
                        />
                      ) : (
                        <svg
                          width="26"
                          height="30"
                          viewBox="0 0 26 30"
                          fill="#455A64"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M25.3695 22.9408C24.63 22.3564 23.729 21.9356 22.7515 21.7316L18.0914 20.7966C17.6026 20.7031 17.2499 20.2654 17.2499 19.7554V18.6865C17.5495 18.2657 17.8321 17.7069 18.1275 17.1225C18.357 16.6699 18.7034 15.9878 18.8755 15.8114C19.819 14.8636 20.7306 13.799 21.0133 12.4263C21.2768 11.1385 21.0175 10.4628 20.7136 9.91875C20.7136 8.56087 20.6711 6.86087 20.3503 5.62412C20.312 3.94962 20.0081 3.00825 19.2431 2.18375C18.7034 1.59938 17.9086 1.46337 17.269 1.355C17.0183 1.3125 16.6719 1.253 16.5444 1.185C15.4118 0.573 14.2919 0.273375 12.9553 0.25C10.1566 0.36475 6.71627 2.1455 5.56452 5.32025C5.20752 6.28713 5.24364 7.8745 5.27339 9.1495L5.24577 9.91662C4.97164 10.4521 4.70177 11.1321 4.96739 12.4241C5.24789 13.799 6.15952 14.8658 7.12002 15.8263C7.27727 15.9878 7.63215 16.6763 7.8659 17.131C8.16552 17.7133 8.45027 18.27 8.7499 18.6886V19.7575C8.7499 20.2654 8.39502 20.7031 7.90415 20.7987L3.23977 21.7337C2.26865 21.9399 1.36764 22.3564 0.63027 22.9408C0.40077 23.1256 0.25627 23.3955 0.232895 23.6887C0.20952 23.982 0.30727 24.2689 0.504895 24.4878C3.67964 27.9897 8.23352 30 12.9999 30C17.7663 30 22.3223 27.9919 25.4949 24.4878C25.6925 24.2689 25.7924 23.9799 25.7669 23.6866C25.7435 23.3934 25.599 23.1235 25.3695 22.9408Z" />
                        </svg>
                      )}
                    </NavLink>
                  </div>
                )}
                <NavLink
                  onClick={() => setIsMenuOpen(false)}
                  to={`${isLoggedIn ? '/user/post' : '/login'}`}
                  className="menu__button"
                >
                  {isLoggedIn ? (
                    <svg
                      width="21"
                      height="19"
                      viewBox="0 0 21 19"
                      fill="#455A64"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1.12492 0.125H19.8749C20.1512 0.125 20.4161 0.234747 20.6115 0.430097C20.8068 0.625447 20.9166 0.890399 20.9166 1.16667V17.8333C20.9166 18.1096 20.8068 18.3746 20.6115 18.5699C20.4161 18.7653 20.1512 18.875 19.8749 18.875H1.12492C0.848651 18.875 0.583699 18.7653 0.388349 18.5699C0.192999 18.3746 0.083252 18.1096 0.083252 17.8333V1.16667C0.083252 0.890399 0.192999 0.625447 0.388349 0.430097C0.583699 0.234747 0.848651 0.125 1.12492 0.125ZM18.8333 7.41667H2.16659V16.7917H18.8333V7.41667ZM3.20825 3.25V5.33333H5.29159V3.25H3.20825ZM7.37492 3.25V5.33333H9.45825V3.25H7.37492Z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M10,17V14H3V10H10V7L15,12L10,17M10,2H19A2,2 0 0,1 21,4V20A2,2 0 0,1 19,22H10A2,2 0 0,1 8,20V18H10V20H19V4H10V6H8V4A2,2 0 0,1 10,2Z" />
                    </svg>
                  )}
                  {isLoggedIn ? t('Advertise') : t('Log in')}
                </NavLink>
              </div>
              <div className="nav__overlay" onClick={(e) => menuClose(e)} />
            </nav>
            <div className="menu__open" onClick={() => setIsMenuOpen(true)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M3 4h18v2H3V4zm6 7h12v2H9v-2zm-6 7h18v2H3v-2z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default AppMenu;
