import React from 'react';
import AppMenu from "../AppMenu";
import AppFooter from "../AppFooter";


const PageLayout = ({children, footer = true}) => {
    return (
        <>
            <AppMenu/>
            <main className="app__wrapper">
                {children}
            </main>
            {footer ? <AppFooter/> : null}
        </>
    );
};

export default PageLayout;