import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import './Assets/Libs/bootstrap-reboot.min.css';
import './Assets/Libs/bootstrap-grid.min.css';
import loadable from "@loadable/component";
import {MainSpinner} from "./Components/Routes/Routes";


const App = loadable(() => import('./Components/App'));

ReactDOM.render(
    <Router>
        <Suspense fallback={<MainSpinner/>}>
            <App/>
        </Suspense>
    </Router>,
    document.getElementById('root'),
);
